import styled from "styled-components/macro";

export const Button = styled.button``;
export const Input = styled.div``;
export const CardElements = styled.div``;
export const CardInput = styled.div``;
export const PaymentMethod = styled.div``;
export const CardDetails = styled.div``;
export const Divider = styled.div``;
export const Beige = styled.div``;

export const StyledCheckoutForm = styled.form`
    max-width: 520px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;

    h2 {
        font-size: 36px;
        font-style: italic;
        text-align: center;
        margin-bottom: 20px;
    }

    ${Button} {
        border: none;
        outline: none;
        position: relative;
        display: block;
        width: max-content;
        margin: 0 auto 45px;
        font-family: 'Gill Sans Nova';
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;

        span {
            font-weight: bold;
            text-decoration: underline;
            pointer-events: none;
        }
    }

    ${Input} {
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
    }

    label {
        font-family: 'Gill Sans Nova';
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: .15em;
        display: block;
        margin-left: 15px;
        margin-bottom: 8px;

        .label-optional {
            opacity: .5;
        }
    }

    input::placeholder {
        letter-spacing: 0.04em;
    }

    input[name="password"] {
        padding-right: 45px;
    }

    .icon-eye {
        top: 39px;
        right: 15px;
    }

    .invalid {
        background: rgba(255, 0, 0, 0.08) !important;
        border: 1px solid rgba(255, 0, 0, 0.63) !important;

        &:focus {
            border-bottom: 1px solid #000 !important;
            border: none !important;
            background-color: #fffff3 !important;
            color: #000;
        }
    }

    #password-length {
        display: block;
        margin: -28px 0 12px 12px;
        color: rgba(255, 0, 0, 0.8) ;
        visibility: hidden;

        &:has(+ .input-description) {
            margin: 0 0 -25px 12px;

            &[style="visibility: hidden;"] {
                margin-top: -25px;
                margin-bottom: 0px;
            }

            &[style="visibility: visible;"] {
                margin-bottom: -15px;
            }
        }
    }

    ${CardDetails} {
        background: #f4eedc;

        > * {
             max-width: 320px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    ${CardElements} {
        background: #FFFFF3;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    ${CardInput} {
        position: relative;
        padding: 13px;
        cursor: text;

        .StripeElement--invalid {
            box-shadow: 0 0 0 13px rgba(255, 0, 0, 0.08);

            &::before {
                content: '';
                position: absolute;
                background: rgba(255, 0, 0, 0.08);
                border: 1px solid rgba(255, 0, 0, 0.63);
                box-sizing: border-box;
                z-index: 1;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 13px;
                background: #ffd9ce;
                width: 51.5%;
                height: 2px;
                z-index: 1;
            }
        }

        &:first-of-type {
            position: relative;
            grid-column: 1/-1;
            border-bottom: 1px solid #BDBAB1;

            .StripeElement--invalid {
                &::before {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: calc(100% + 1px);
                }
            }
        }

        &:nth-of-type(2) {
            .StripeElement--invalid {
                &::before {
                    top: -1px;
                    left: 0;
                    width: calc(100% + 1px);
                    height: calc(100% + 1px);
                }
            }
        }

        &:last-of-type {
            border-left: 1px solid #BDBAB1;

            .StripeElement--invalid {
                &::before {
                    top: -1px;
                    left: -1px;
                    width: calc(100% + 1px);
                    height: calc(100% + 1px);
                }
            }
        }

        img {
            position: absolute;
            top: 13px;
            right: 13px;
            width: 130px;
            height: 20px;
        }
    }

    ${PaymentMethod} {
        overflow: hidden;
        background: #f4eedc;
        padding: 57px 0px 30px;

        input {
            display: none;

            & + label {
                .icon-checked {
                    display: none;
                }

                .icon-not-checked {
                    display: inline-block;
                }
            }

            &:checked + label {
                .icon-checked {
                    display: inline-block;
                }

                .icon-not-checked {
                    display: none;
                }
            }
        }

        label {
            display: flex;
            align-items: center;
            text-transform: none;
            font-size: 16px;
            cursor: pointer;
        }

        i {
            margin-right: 3px;
        }

        /*
        * New Payment Method 
        */

        p {
            font-family: 'Gill Sans Nova';
            font-size: 12px;
            text-align: center;
            margin-bottom: 46px;

            a {
                text-decoration: underline;
            }
        }

        img {
            display: block;
            width: 244px;
            height: 50px;
            margin: 0 auto 36px;
            cursor: pointer;
        }

        ${Divider} {
            position: relative;
            font-family: 'Gill Sans Nova';
            font-size: 18px;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.72px;
            text-align: center;
            color: #979797;
            width: max-content;
            margin: 0 auto;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 15px;
                width: 179px;
                height: 1px;
                background: #979797;
            }

            &::before {
                left: -187px;
            }

            &::after {
                right: -187px;
            }
        }
    }

    button[type=submit] {
        width: 100% !important;
        max-width: 320px !important;
        margin-left: auto;
        margin-right: auto;
        padding: 0 !important;
        height: 48px;
        line-height: 1 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 5px !important;

        &.submitting {
            font-size: 0 !important;
            padding-bottom: 0 !important;
            
            svg {
                display: block;
            }
        }

        svg {
            display: none;
        }
    }

    ${Beige} {
        background: #f4eedc;
        padding-bottom: 30px;
        max-width: 320px;
        margin: 0 auto;
    }
`;